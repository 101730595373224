import request from '@/utils/request'
// 查询公司列表
export function listCompany (query) {
  return request({
    url: '/gx/gx-company/list',
    method: 'get',
    params: query
  })
}
// 查询公司分页
export function pageCompany (query) {
  return request({
    url: '/gx/gx-company/page',
    method: 'get',
    params: query
  })
}

// 查询公司详细
export function getCompany (data) {
  return request({
    url: '/gx/gx-company/detail',
    method: 'get',
    params: data
  })
}
// 新增公司
export function addCompany (data) {
  return request({
    url: '/gx/gx-company/add',
    method: 'post',
    data: data
  })
}
// 修改公司
export function updateCompany (data) {
  return request({
    url: '/gx/gx-company/edit',
    method: 'post',
    data: data
  })
}

// 删除公司
export function delCompany (data) {
  return request({
    url: '/gx/gx-company/delete',
    method: 'post',
    data: data
  })
}
