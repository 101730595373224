<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="公司名称"
        prop="name"
      >
        <a-input
          v-model="form.name"
          :maxLength="20"
          placeholder="请输入公司名称"
        />
      </a-form-model-item>
      <a-form-model-item
        label="省市区"
        prop="address"
      >
        <v-distpicker
          :province="form.provinceCode"
          :city="form.cityCode"
          :area="form.areaCode"
          @province="onChangeProvince"
          @city="onChangeCity"
          @area="onChangeArea"
        >
        </v-distpicker>
      </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="status"
      >
        <a-radio-group
          button-style="solid"
          v-model="form.status"
        >
          <a-radio-button :value="1">
            启用
          </a-radio-button>
          <a-radio-button :value="0">
            禁用
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="备注"
        prop="remark"
      >
        <a-input
          v-model="form.remark"
          :maxLength="30"
          placeholder="请输入设备注"
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCompany, addCompany, updateCompany } from '@/api/project/company'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        status: 1,
        remark: null,
        provinceCode: null,
        cityCode: null,
        areaCode: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 选择省
    onChangeProvince (e) {
      this.form.provinceCode = e.value
    },
    // 选择市
    onChangeCity (e) {
      this.form.cityCode = e.value
    },
    // 选择区
    onChangeArea (e) {
      this.form.areaCode = e.value
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        name: null,
        status: 1,
        remark: null,
        provinceCode: null,
        cityCode: null,
        areaCode: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCompany({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateCompany(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addCompany(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
