<template>
  <!-- 增加修改 -->
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="用户昵称"
        prop="nickName"
      >
        <a-input
          v-model="form.nickName"
          placeholder="请输入"
          :maxLength="30"
        />
      </a-form-model-item>
      <!-- <a-form-model-item label="部门" prop="tenantId">
        <a-tree-select
          v-model="form.tenantId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item> -->
      <a-form-model-item
        label="手机号"
        prop="phoneNumber"
      >
        <a-input
          v-model="form.phoneNumber"
          placeholder="请输入"
          :maxLength="11"
        />
      </a-form-model-item>
      <!-- <a-form-model-item
        label="邮箱"
        prop="email"
      >
        <a-input
          v-model="form.email"
          placeholder="请输入"
        />
      </a-form-model-item> -->
      <a-form-model-item
        label="用户名"
        prop="userName"
        v-if="form.id == undefined"
      >
        <a-input
          v-model="form.userName"
          placeholder="请输入"
        />
      </a-form-model-item>
      <a-form-model-item
        label="密码"
        prop="password"
        v-if="form.id == undefined"
      >
        <a-input-password
          v-model="form.password"
          placeholder="请输入"
          :maxLength="20"
        />
      </a-form-model-item>
      <a-form-model-item
        label="性别"
        prop="sex"
      >
        <a-radio-group
          v-model="form.sex"
          button-style="solid"
        >
          <a-radio-button
            v-for="(d, index) in sexOptions"
            :key="index"
            :value="parseInt(d.value)"
          >{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="status"
      >
        <a-radio-group
          v-model="form.status"
          button-style="solid"
        >
          <a-radio-button
            v-for="(d, index) in statusOptions"
            :key="index"
            :value="parseInt(d.value)"
          >{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item
        label="用户类型"
        prop="userType"
      >
        <a-radio-group
          v-model="form.userType"
          button-style="solid"
          @change="handleType"
        >
          <a-radio-button value="00">系统用户</a-radio-button>
          <a-radio-button value="1">公司管理员</a-radio-button>
          <a-radio-button value="2">员工</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item
        label="角色"
        prop="roleId"
        v-if="!showUser && role !== 'GS'"
      >
        <a-select
          v-model="form.roleId"
          placeholder="请选择"
          @change="handleChange"
        >
          <a-select-option
            v-for="(d, index) in roleOptions"
            :key="index"
            :value="d.id"
          >
            {{ d.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="公司"
        prop="tenantId"
        v-if="showId"
      >
        <div class="slect_icon">
          <a-select
            v-model="form.tenantId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="(d, index) in companyList"
              :key="index"
              :value="d.id"
            >
              {{ d.name }}
            </a-select-option>
          </a-select>
          <a-icon
            type="plus-circle"
            class="icon_cir"
            @click="$refs.createType.handleAdd()"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="备注"
        prop="remark"
      >
        <a-input
          v-model="form.remark"
          placeholder="请输入"
          type="textarea"
          allow-clear
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <companyFrom
      ref="createType"
      @ok="getCompany"
    ></companyFrom>
  </a-drawer>
</template>

<script>

import { getUser, addUser, updateUser } from '@/api/system/user'
import { listCompany } from '@/api/project/company'
import { mapGetters } from 'vuex'
import companyFrom from '../../../project/company/modules/CreateForm.vue'
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    sexOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    companyFrom
  },
  data () {
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,12}$/.test(value)) {
        callback(new Error('请输入密码字母和数字(6-12位)'))
      } else {
        if (this.form.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    return {
      submitLoading: false,
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 默认密码
      initPassword: undefined,
      formTitle: '',
      // 表单参数
      form: {
        id: undefined,
        tenantId: 0,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        userType: '00',
        remark: undefined,
        postIds: [],
        roleId: undefined,
        roleIds: []
      },
      companyList: [],
      showId: false,
      open: false,
      showUser: false,
      role: '',
      rules: {
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '用户昵称不能为空', trigger: 'blur' }
        ],
        roleId: [
          { required: true, message: '角色不能为空', trigger: 'change' }
        ],
        tenantId: [{ required: true, message: '公司不能为空', trigger: 'change' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { required: true, validator: validateConfirmPass, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请正确填写邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        phoneNumber: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getConfigKey('sys.user.initPassword').then(response => {
      this.initPassword = response.msg
    })
    this.role = this.roles[0]
    if (this.role !== 'GS') {
      this.getCompany()
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'tenantIds'
    ])
  },
  watch: {
  },

  methods: {
    handleType (e) {
      console.log(e, 'dddadsdd')
    },
    getCompany () {
      listCompany().then(res => {
        console.log(res, '滴滴滴滴滴滴')
        this.companyList = res.data
      })
    },
    onClose () {
      this.open = false
    },
    handleChange (e) {
      console.log(this.roleOptions, 'roleOptions')
      const num = this.roleOptions.filter(v => v.id === e)[0]
      console.log(e, num, 'ddddddd嘟嘟嘟嘟')
      this.form.roleId = e
      this.form.roleIds = [e]
      if (num.roleKey === 'ptAdmin' || num.roleKey === 'admin') {
        this.form.userType = '00'
      } else if (num.roleKey === 'GS') {
        this.form.userType = '1'
      } else {
        this.form.userType = '2'
      }
      if (num.roleKey !== 'ptAdmin') {
        this.showId = true
      } else {
        this.showId = false
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        tenantId: undefined,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phoneNumber: undefined,
        email: undefined,
        sex: 0,
        status: 1,
        userType: '00',
        remark: undefined,
        postIds: [],
        roleId: undefined
      }
      this.showId = false
    },
    /** 新增按钮操作 */
    handleAdd (e) {
      console.log(e, '对对对的多多多')
      this.reset()
      // this.formType = 1
      // this.open = true
      // this.formTitle = '添加'
      if (e) {
        this.form.roleId = '11'
        this.showUser = true
        this.open = true
      } else {
        this.showUser = false
        getUser().then(response => {
          this.postOptions = response.data.posts
          this.roleOptions = response.data.roles
          this.open = true
          this.formTitle = '新增用户'
          this.form.password = this.initPassword
        })
        console.log(this.roles, '滴滴滴滴滴滴')
        // if (this.roles[0]) {
        //   this.form.roleIds = ['6']
        //   this.form.roleId = '6'
        //   this.form.tenantId = this.tenantIds
        // }
        console.log(11111)
        // this.$emit('select-tree')
      }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      console.log(row, ids, 'row, idsrow, ids')
      this.reset()
      this.$emit('select-tree')
      const userId = row ? row.id : ids
      getUser(userId).then(response => {
        this.form = response.data.data
        this.postOptions = response.data.posts
        this.roleOptions = response.data.roles
        this.form.postIds = response.data.postIds
        this.form.roleIds = response.data.roleIds
        const num = row.roles[0]
        this.form.roleId = num.id
        this.form.roleIds = [num.id]
        if (num.roleKey === 'ptAdmin') {

        } else {
          this.showId = true
          this.form.tenantId = row.tenantId
        }
        this.open = true
        this.formTitle = '修改用户'
        this.form.password = undefined
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            this.form.roleIds = [this.form.roleId]
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.slect_icon {
  display: flex;
  align-items: center;

  .icon_cir {
    margin-left: 30px;
    font-size: 25px;
    cursor: pointer;
  }
}
</style>
